/* SERVICES */
import { AppConfigProvider } from './app-config-provider.service'
import { AccountsService } from './accounts.service'
import { AchievementsService } from './achievements.service'
import { AuthGuardAccountOwnerService } from './auth-guard-account-owner.service'
import { AuthGuardService } from './auth-guard.service'
import { AuthGuardUserService } from './auth-guard-user.service'
import { AuthService } from './auth.service'
import { CommonService } from './common.service'
import {
   ConfirmService,
   ConfirmState
} from '../components/confirm-modal-and-service'
import { ContentRegistrationProgressesService } from './content-registration-progresses.service'
import { ContentRegistrationsService } from './content-registrations.service'
import { DateTimeService } from './date-time.service'
import { GlobalPubSubService } from './global-pub-sub.service'
import { GuardedSingletonService } from './guarded-singleton.service'
import { HttpRequestInterceptor } from './http-request-interceptor'
import { HybridWebviewInteropService } from './hybrid-webview-interop.service'
import { NotificationsService } from './notifications.service'
import { LookupService } from './lookup/lookups.service'
import { ProfileService } from './profile.service'
import { PublicOnlyGuardService } from './public-only-guard.service'
import { SubscriptionsService } from './subscriptions.service'
import { UrlService } from './url.service'
import { AppFacade } from './app.facade'

export const services: any[] = [
   AccountsService,
   AchievementsService,
   AppConfigProvider,
   AppFacade,
   AuthGuardAccountOwnerService,
   AuthGuardService,
   AuthGuardUserService,
   AuthService,
   CommonService,
   ConfirmService,
   ConfirmState,
   ContentRegistrationProgressesService,
   ContentRegistrationsService,
   DateTimeService,
   GlobalPubSubService,
   GuardedSingletonService,
   HttpRequestInterceptor,
   HybridWebviewInteropService,
   LookupService,
   NotificationsService,
   ProfileService,
   PublicOnlyGuardService,
   SubscriptionsService,
   UrlService
]

export * from './accounts.service'
export * from './achievements.service'
export * from './app-config-provider.service'
export * from './auth-guard-account-owner.service'
export * from './auth-guard.service'
export * from './auth-guard-user.service'
export * from './auth.service'
export * from './common.service'
export * from '../components/confirm-modal-and-service'
export * from './content-registration-progresses.service'
export * from './content-registrations.service'
export * from './date-time.service'
export * from './global-pub-sub.service'
export * from './guarded-singleton.service'
export * from './http-request-interceptor'
export * from './hybrid-webview-interop.service'
export * from './lookup/lookups.service'
export * from './notifications.service'
export * from './profile.service'
export * from './public-only-guard.service'
export * from './subscriptions.service'
export * from './url.service'
export * from './app.facade'

/* TOKENS */
import { JQUERY_TOKEN } from './JQuery.service'
import { MOMENT_TOKEN } from './moment.service'
import { TOASTR_TOKEN } from './toastr.service'

export { JQUERY_TOKEN, MOMENT_TOKEN, TOASTR_TOKEN }

export * from './JQuery.service'
export * from './moment.service'
export * from './toastr.service'
