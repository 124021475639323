import { ChangeDetectionStrategy, Component, DestroyRef, Input } from '@angular/core'
import { Event, Router, Scroll, RouterLink } from '@angular/router'
import { takeUntilDestroyed } from '@angular/core/rxjs-interop'
import { SITE_DIRECTORY_ROUTES } from '@appShared/site-directory.routes'

@Component({
    selector: 'app-public-header',
    templateUrl: './public-header.component.html',
    styleUrls: ['./public-header.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [RouterLink]
})
export class PublicHeaderComponent {
   @Input() title: string
   hideHeader: boolean

   constructor(
      private _router: Router,
      destroyRef: DestroyRef
   ) {

      this._router.events
         .pipe(takeUntilDestroyed(destroyRef))
         .subscribe((event: Event) => {
            if (event instanceof Scroll) {
               const url = event?.routerEvent?.url
               this.hideHeader = url.includes('registration-landing')
            }
         })
   }

   signIn() {
      this._router.navigate([`${SITE_DIRECTORY_ROUTES.content.uri}`])
   }
}
