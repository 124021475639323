import { Component, DestroyRef, OnInit } from '@angular/core'
import { NgIf, ViewportScroller } from '@angular/common'
import {
   Router,
   Event,
   NavigationStart,
   NavigationEnd,
   NavigationCancel,
   NavigationError,
   RouterOutlet,
   Scroll
} from '@angular/router'
import { CommonService } from './shared/services/common.service'
import { UrlService } from './shared/services/url.service'
import { HybridWebviewInteropService } from './shared/services/hybrid-webview-interop.service'
import { takeUntilDestroyed } from '@angular/core/rxjs-interop'

//declare let gtag: () => void

@Component({
   selector: 'app-root',
   templateUrl: './app.component.html',
   styleUrls: ['./app.component.less'],
   standalone: true,
   imports: [NgIf, RouterOutlet]
})
export class AppComponent implements OnInit {
   previousUrl = '';
   currentUrl = '';
   loading = false;
   showHeaderFooter = false;
   isIframe = false;

   constructor(
      private _commonService: CommonService,
      private _urlService: UrlService,
      private _viewportScroller: ViewportScroller,
      /*injecting hybrid service so it will come alive as soon as angular starts */
      hybridWebviewInteropService: HybridWebviewInteropService,
      router: Router,
      destroyRef: DestroyRef
   ) {

      router.events
         .pipe(takeUntilDestroyed(destroyRef))
         .subscribe((routerEvent: Event) =>
            this._checkRouterEvent(routerEvent)
         )
   }

   ngOnInit(): void {
      this.isIframe = window !== window.parent && !window.opener
   }

   /*
    * private methods
    * */

   private _checkRouterEvent(routerEvent: Event): void {
      if (routerEvent instanceof NavigationStart) {
         this._commonService.setLoading(true)

         return
      }

      if (
         routerEvent instanceof NavigationEnd ||
         routerEvent instanceof NavigationError ||
         routerEvent instanceof NavigationCancel
      ) {
         this._commonService.setLoading(false)

         if (routerEvent instanceof NavigationEnd) {
            this.previousUrl = this.currentUrl
            this.currentUrl = routerEvent.url
            this._urlService.setPreviousUrl(this.previousUrl)

            /* gtag('config', 'UA-7880158-22', {
              page_path: routerEvent.urlAfterRedirects
            }); */
         }

         return
      }

      if (routerEvent instanceof Scroll) {
         // console.log('app-event', e);
         if (routerEvent.position) {
            // backward navigation
            this._viewportScroller.scrollToPosition(routerEvent.position)
         } else if (routerEvent.anchor) {
            // anchor navigation
            this._viewportScroller.scrollToAnchor(routerEvent.anchor)
         } else {
            // forward navigation
            this._viewportScroller.scrollToPosition([0, 0])
         }

         return
      }
   }
}
