import { ChangeDetectionStrategy, Component, DestroyRef } from '@angular/core'
import { takeUntilDestroyed } from '@angular/core/rxjs-interop'
import { Event, Router, Scroll } from '@angular/router'

@Component({
    selector: 'app-site-footer',
    templateUrl: './site-footer.component.html',
    styleUrls: ['./site-footer.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true
})

export class SiteFooterComponent {
   currentYear = (new Date()).getFullYear()
   hideFooter: boolean

   constructor(
      router: Router,
      destroyRef: DestroyRef
   ) {

      router.events
         .pipe(takeUntilDestroyed(destroyRef))
         .subscribe((event: Event) => {
            if (event instanceof Scroll) {
               const url = event?.routerEvent?.url
               this.hideFooter = url.includes('registration-landing')
            }
         })
   }
}
