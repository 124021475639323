<div class="container">
   <div class="row sections">

      <div class="col-12 text-center brightcove-player">

         <div class="rounded">

            <div brightcove-experience-player
                 [attr.data-experience-id]="brightcoveSettings.experiences.dashboard"
                 [attr.data-user-id]="brightcoveClientUserId">
            </div>

         </div>

      </div>

      <div class="col-md-6 mb-3">

         <a href="{{teamHubUri}}" target="_blank">
            <img [src]="whatsGoingOnImageUri" title="What's New?'" class="img-link w-100" />
         </a>

      </div>

      <div class="col-md-6 mb-3">

         <a routerLink="/{{learningJourneysUri}}">
            <img [src]="learningJourneysImageUri" title="Learning Journeys" class="img-link w-100" />
         </a>

      </div>

   </div>

</div>

