import { inject, Injectable } from '@angular/core'
import {
   ActivatedRouteSnapshot,
   Router,
   RouterStateSnapshot
} from '@angular/router'
import { Observable, of } from 'rxjs'
import { AppFacade, CommonService } from '@appShared/services'

@Injectable({ providedIn: 'root' })
export class AuthGuardUserService {
   private _router = inject(Router)
   private _commonService = inject(CommonService)
   private _appFacade = inject(AppFacade)

   canActivate(
      route: ActivatedRouteSnapshot,
      state: RouterStateSnapshot
   ): Observable<boolean> {

      const returnUrl = state.url
      const appFacade = this._appFacade
      const user = appFacade.currentUser()

      if (!(user?.profile)) {

         this._commonService.navigateToLogin(returnUrl)
         return of(false)
      }

      if (!(user.isAdmin || user.isRep)) {
         this._router.navigate(['/401'])
         return of(false)
      }

      return of(true)
   }
}
