import { ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, Input, OnChanges, OnInit, SimpleChanges, TrackByFunction } from '@angular/core'
import { NgIf, NgFor, NgClass } from '@angular/common'
import { ActivatedRoute, Router, RouterLink } from '@angular/router'
import { Observable, take } from 'rxjs'
import { AppFacade } from '@appShared/services/app.facade'
import { CommonService } from '@appShared/services/common.service'
import { ContainersService } from '@appContent/shared/services/containers.service'
import { ToastrType } from '@appShared/services/toastr.service'
import { IContainer } from '@appShared/interfaces/[Model-based]/container.interface'
import { IContentRegistration } from '@appShared/interfaces/[Model-based]/content-registration.interface'
import { Completion_ } from '@appShared/services/lookup/[CodeGen]/completion.domain'
import { ComponentType_ } from '@appShared/services/lookup/[CodeGen]/component-type.domain'
import { ContainerType_ } from '@appShared/services/lookup/[CodeGen]/container-type.domain'
import { SITE_DIRECTORY_ROUTES } from '@appShared/site-directory.routes'
import { environment } from '@appEnvironments/environment'

@Component({
   selector: 'app-container-list',
   templateUrl: './container-list.component.html',
   styleUrls: ['./container-list.component.less'],
   changeDetection: ChangeDetectionStrategy.OnPush,
   standalone: true,
   imports: [NgIf, NgFor, NgClass, RouterLink]
})
export class ContainerListComponent implements OnInit, OnChanges {
   @Input() title: string
   @Input() parentContainerId: number
   @Input() moduleName: string
   @Input() componentTypeCode?: ComponentType_

   private _currentUser = inject(AppFacade).currentUser
   private _activatedRoute = inject(ActivatedRoute)
   private _router = inject(Router)
   private _commonService = inject(CommonService)
   private _containersService = inject(ContainersService)
   private _cd = inject(ChangeDetectorRef)

   modules: IContainer[] = []
   completionTypes = Completion_
   completedTokenModules: IContainer[] = []
   incompleteTokenModules: IContainer[] = []
   notStartedTokenModules: IContainer[] = []
   standardModules: IContainer[] = []
   private _contentRoutes = SITE_DIRECTORY_ROUTES.content
   learningJourneyUri = this._contentRoutes.learningJourneys.uri
   isLoaded = false

   ngOnInit(): void {
      this.title = this.title || 'Module List'
   }

   ngOnChanges(changes: SimpleChanges): void {
      if (changes?.['parentContainerId']?.previousValue != changes?.['parentContainerId']?.currentValue) {
         this._setArea()
      }
   }

   /*
   * private methods
   * */

   private _setArea(): void {
      let resolveModules = (): Observable<IContainer[]> => {

         if (this.parentContainerId) return this._containersService.getModules(this.parentContainerId)

         const snapshot = this._activatedRoute.snapshot
         this.componentTypeCode = this.componentTypeCode || snapshot.data['componentTypeCode'] || null

         return this._containersService.getAreas()
      }

      resolveModules().pipe(take(1))
         .subscribe((modules) => {

            this._createModuleLinks(modules, this._currentUser()?.profile?.contact?.contentRegistrations)

            this.isLoaded = true

            this._cd.markForCheck()
         })
   }

   private _createModuleLinks(modules: IContainer[], contentRegistrations?: IContentRegistration[]) {


      /*TODO - remove below - testing  !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!*/
      //modules = [...modules, ...modules]
      //let insertModule = { ...modules[2], id: 7, "$id": '5' } as IContainer
      //modules.push(insertModule)
      /*TODO - remove above - testing  !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!*/

      const courseImagesUri = `${environment.publicCdn.uri}/${environment.publicCdn.courseImagesFolder}`

      const statusImageBaseUri = `${courseImagesUri}/course-`
      const completedStatusImageUri = `${statusImageBaseUri}completed.png`
      const incompleteModulesUri = `${statusImageBaseUri}in-progress.png`
      const notStartedStatusImageUri = `${statusImageBaseUri}locked.png`
      const currentUserIsAdminAndNotRep = this._currentUser().isAdmin && !this._currentUser().isRep

      modules.forEach((module: any) => {
         const moduleInfo = module.info
         const token = moduleInfo.encodedToken
         module.token = token

         if (currentUserIsAdminAndNotRep) {
            moduleInfo.isHidden = false
            moduleInfo.isLocked = false
         }

         module.isAreaContainer = module.typeCode === ContainerType_.Area

         if (!module.isAreaContainer && token) {
            if (moduleInfo.isComplete) {
               module.statusImageUri = completedStatusImageUri
               this.completedTokenModules.push(module)
            }
            else if (moduleInfo.isLocked) {
               module.statusImageUri = notStartedStatusImageUri
               this.notStartedTokenModules.push(module)
            }
            else if (moduleInfo.isInProgress) {
               module.statusImageUri = incompleteModulesUri
               this.incompleteTokenModules.push(module)
            }
            else {
               //no more default status to allow for open courses
               //module.statusImageUri = notStartedStatusImageUri
               this.notStartedTokenModules.push(module)
            }
         } else {
            module.standard = true
            this.standardModules.push(module)
         }

         if (!module.iconImageUri) {
            const hostedContentToken = module.info.hostedContentToken
            const baseImageUri = module.isAreaContainer
               ? `${environment.publicCdn.uri}/${environment.publicCdn.areaImagesFolder}`
               : `${environment.publicCdn.uri}/${environment.publicCdn.courseImagesFolder}`
            module.iconImageUri = hostedContentToken
               ? `${baseImageUri}/${hostedContentToken}.png`
               : /*TODO area image path*/`${baseImageUri}/${'first-years'}.png`
            //'https://via.placeholder.com/500x281' /*  TODO?? maybe different url if nothing there */
         }
      })

      /* these are children containers */
      if (this.parentContainerId) {
         //if no "incomplete" module, then pull first non-started
         if (!this.incompleteTokenModules.length && this.notStartedTokenModules.length) {
            let firstNotStartedModule = this.notStartedTokenModules[0]

            //  change statusImageUri label and locked property
            this.incompleteTokenModules.push({
               ...firstNotStartedModule,
               statusImageUri: incompleteModulesUri,
               info: {
                  ...firstNotStartedModule.info,
                  isLocked: false
               }
            } as any)

            //remove that first module from notStarted
            this.notStartedTokenModules.shift()
         }
      }

      // add attribute to array for ng-switch in markup to handle section titles
      this.completedTokenModules['progressCompletionCode'] = Completion_.Completed
      this.incompleteTokenModules['progressCompletionCode'] = Completion_.Incomplete
      this.notStartedTokenModules['progressCompletionCode'] = Completion_.NotStarted

      /* filter out hidden modules */
      const stagedModules = [
         ...this.incompleteTokenModules,
         ...this.notStartedTokenModules,
         ...this.completedTokenModules,
         ...this.standardModules]

      this.modules = stagedModules.filter(mod => !mod.info.isHidden)
   }

   /*
  * public methods
  * */

   trackByModuleId: TrackByFunction<IContainer> = (index, module) => module.id

   goToRoute(module: IContainer) {
      if (!module.info.isLocked) {
         if (module.typeCode === ContainerType_.Area) {
            this._containersService.setSelectedJourney(module)
            const areaName = module.name.replace('&', 'and').replace(' ', '-').toLowerCase()
            const url = `/${this._contentRoutes.learningJourneys.uri}/${module.id}/${areaName}`
            this._router.navigate([url])
            return
         }

         const token = module.info.encodedToken
         const queryParams = token ? { queryParams: { token, pcId: (this.parentContainerId || 0) } } : {}
         const route = token
            ? `/${environment.playerUri}`
            : `/${this._contentRoutes.uri}/${this.parentContainerId}/${module.id}/c/${module.urlSlug || 'module'}`

         this._router.navigate([`${route}`], queryParams)
      } else {
         this._commonService.messageUser('This course is currently locked', null, ToastrType.info)
      }
   }
}
