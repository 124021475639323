import { Injectable } from '@angular/core'
import { EMPTY, Observable, catchError, combineLatestWith, of, switchMap, take } from 'rxjs'
import { AppFacade } from '@appShared/services/app.facade'
import { SITE_DIRECTORY_ROUTES } from '@appShared/site-directory.routes'

@Injectable({ providedIn: 'root' })
export class AppConfigProvider {

   constructor(

      private _appFacade: AppFacade
   ) { }

   init(): Observable<boolean> {

      return this._appFacade.initializeSite().pipe(
         take(1),
         switchMap(() => of(true)),
         catchError((err) => {
            console.log('Error occurred loading site config:', err)
            return of(false)
         })
      )
   }
}
