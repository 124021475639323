import { Injectable, inject } from '@angular/core'
import {
   ActivatedRouteSnapshot,
   Router,
   RouterStateSnapshot
} from '@angular/router'
import { Observable, of } from 'rxjs'
import { AppFacade, CommonService } from '@appShared/services'
import { SubscriptionStatus_ } from '@appShared/services/lookup/[CodeGen]/subscription-status.domain'
import { IProfile } from '@appShared/interfaces/[Model-based]/profile.interface'
import { SITE_DIRECTORY_ROUTES } from '@appShared/site-directory.routes'

@Injectable({ providedIn: 'root' })
export class AuthGuardAccountOwnerService {
   private _router = inject(Router)
   private _commonService = inject(CommonService)
   private _appFacade = inject(AppFacade)

   canActivate(
      route: ActivatedRouteSnapshot,
      state: RouterStateSnapshot
   ): Observable<boolean> {

      const returnUrl = state.url
      const appFacade = this._appFacade
      const user = appFacade.currentUser()

      if (!(user?.profile)) {

         this._commonService.navigateToLogin(returnUrl)
         return of(false)
      }

      if (user.isAdmin || user.isRep) {
         return of(true)
      }

      const memberRoutes = SITE_DIRECTORY_ROUTES.member
      const setPasswordUrl = `/${memberRoutes.setPassword.uri}`

      if (user.mustResetPassword
         && !returnUrl.startsWith(setPasswordUrl)) {
         // force them to set-password
         this._router.navigate([setPasswordUrl])

         return of(false)
      }

      if (!user.mustResetPassword
         && returnUrl.startsWith(setPasswordUrl)) {
         // don't need set-password
         this._router.navigate([memberRoutes.uri])

         return of(false)

      }

      const profile = user?.profile as IProfile
      const accountRoutes = memberRoutes.account
      if (!profile.info.isAccountOwner) {
         this._router.navigate([accountRoutes.uri])

         return of(false)
      }

      const accountBillingUrl = `/${accountRoutes.billing.uri}`
      const accountMembershipUrl = `/${accountRoutes.membership.uri}`
      const accountProfileUrl = `/${accountRoutes.profile.uri}`
      const subscriptions = profile?.account?.subscriptions
      const currentSubscription = subscriptions.find(sub => [
         SubscriptionStatus_.Active,
         SubscriptionStatus_.Paused,
         SubscriptionStatus_.OnHold,
         SubscriptionStatus_.Cancelled,
         SubscriptionStatus_.Ended].includes(sub.statusCode))

      if (([SubscriptionStatus_.OnHold,
      SubscriptionStatus_.Paused,
      SubscriptionStatus_.Cancelled,
      SubscriptionStatus_.Ended].includes(currentSubscription?.statusCode)
         && (returnUrl !== accountMembershipUrl && returnUrl !== accountBillingUrl && returnUrl !== accountProfileUrl))) {

         if (currentSubscription.statusCode === SubscriptionStatus_.OnHold) {
            this._router.navigate([accountBillingUrl])
         } else {
            this._router.navigate([accountMembershipUrl])
         }

         return of(false)
      }

      return of(true)
   }
}
