<div class="container socials-page">

   <div class="socials-wrapper d-flex flex-column flex-md-row flex-fill justify-content-center align-items-center gap-2 mb-5">

      <section class="bg-white rounded p-5 d-flex flex-column gap-4">
         <h1 class="text-center">Follow us on Social</h1>

         <p class="text-center">Want to keep up with updates and what's going on<br/> at Southwestern Advantage? Follow us on social:</p>

         <div class="d-flex justify-content-center align-items-center">

            <a href="{{external_uris.facebook.uri}}" class="social-link flex-grow-1" target="_blank">
               <img src="/assets/images/social/facebook.png" alt="facebook" />
            </a>

            <a href="{{external_uris.instagram.uri}}" class="social-link flex-grow-1" target="_blank">
               <img src="/assets/images/social/instagram.png" alt="instagram" />
            </a>

            <a href="{{external_uris.youtube.uri}}" class="social-link flex-grow-1" target="_blank">
               <img src="/assets/images/social/youtube.png" alt="youtube" />
            </a>

            <a href="{{external_uris.linked_in.uri}}" class="social-link flex-grow-1" target="_blank">
               <img src="/assets/images/social/linked-in.png" alt="linked-in" />
            </a>

         </div>

      </section>

   </div>


</div>

