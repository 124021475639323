import { ChangeDetectionStrategy, Component, inject, OnInit } from '@angular/core'
import { Title } from '@angular/platform-browser'
import { IFramePlayerComponent } from '@appShared/components/iframe-player/iframe-player.component'
import { SITE_DIRECTORY_ROUTES } from '@appShared/site-directory.routes'
import { environment } from '@appEnvironments/environment'

@Component({
    selector: 'app-content-video-library',
    styles: [`
      .page-title {
         background-color: black!important;
         color: white!important;
      }
   `],
    template: `
      <div class="d-flex flex-column h-100 w-100 bg-white">
         <div class="fs-2 text-center pt-2 fw-bold text-uppercase page-title">{{videoLibraryTitle}}</div>
         <app-iframe-player [frameSourceInput]="videoLibraryUri"></app-iframe-player>
      </div>
   `,
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [IFramePlayerComponent]
})
export class VideoLibraryComponent implements OnInit {
   private _titleService = inject(Title)
   videoLibraryUri: string
   videoLibrary = SITE_DIRECTORY_ROUTES.content.videoLibrary
   videoLibraryTitle = this.videoLibrary.title

   ngOnInit(): void {
      setTimeout(() => this._titleService.setTitle(`SWA - ${this.videoLibraryTitle}`))

      /* this src for brightcove personalities cannot
         be contained in a player - redirect to
         our iframeplayercomponent and set the src
      */
      const experienceUri = encodeURIComponent(environment.brightcove.experiences.addedValuePortalUrl)
      if (experienceUri) {
         // if need to direct to the /player page...
         //const queryParams = { queryParams: { src: experienceUri } }
         //router.navigate([`${environment.playerUri}`], queryParams)

         this.videoLibraryUri = experienceUri
      }
   }
}
