import { ChangeDetectionStrategy, Component, ElementRef, inject, OnInit, ViewChild } from '@angular/core'
import { Title } from '@angular/platform-browser'
import { RouterLink } from '@angular/router'
import { UrlService } from '@appShared/services/url.service'
import { HybridWebviewInteropService } from '@appShared/services/hybrid-webview-interop.service'
import { AppFacade } from '@appShared/services/app.facade'
import { BrightcoveExperiencePlayerDirective } from '@appShared/directives/brightcove-experience-player.directive'
import { SITE_DIRECTORY_ROUTES } from '@appShared/site-directory.routes'
import { EXTERNAL_URIS } from '@appShared/external.uris'
import { environment } from '@appEnvironments/environment'

@Component({
    selector: 'app-content-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [BrightcoveExperiencePlayerDirective, RouterLink]
})
export class DashboardComponent implements OnInit {
   private _hybridService = inject(HybridWebviewInteropService)
   private _urlService = inject(UrlService)
   user = inject(AppFacade).currentUser

   //https://medium.com/@venkateshprasad.devaraj/integrating-brightcove-html5-player-with-angular-2-83a81fd2c8e7
   @ViewChild('brightcoveVideo') brightcoveVideoElement!: ElementRef
   brightcoveClientUserId = this.user().profile.contact.auditId
   brightcoveSettings: any = environment.brightcove
   brightcoveScript = `https://players.brightcove.net/${this.brightcoveSettings.accountId}/experience_${this.brightcoveSettings.experiences.dashboard}/live.js`
   brightcoveScriptVideoJS = `https://players.brightcove.net/${this.brightcoveSettings.accountId}/${this.brightcoveSettings.playerId}_default/index.min.js`

   private _dashboardImagesUri = `${environment.publicCdn.uri}/${environment.publicCdn.dashboardImagesFolder}`
   private _currentTime = (new Date()).getTime()

   private _routes = SITE_DIRECTORY_ROUTES
   learningJourneysImageUri = `${this._dashboardImagesUri}/learning-journeys.png?=${this._currentTime}`
   learningJourneysUri = this._routes.content.learningJourneys.uri

   private _external_uris = EXTERNAL_URIS
   whatsGoingOnImageUri = `${this._dashboardImagesUri}/whats-going-on.png?=${this._currentTime}`
   teamHubUri = this._external_uris.team_hub.uri

   // for future use when we have "dealer info"
   //teamHubUri = computed(() => {
   //   const dealerInfo = this.user()?.
   //   const teamHubRoute = this._external_uris.team_hub


   //   if (dealerInfo) {
   //      return !dealerInfo.isHighlander
   //         ? dealerInfo.isUSDealer
   //            ? teamHubRoute.uri
   //            : teamHubRoute.alt_uri
   //         : null
   //   }

   //   return teamHubRoute.uri
   //})

   constructor(
      titleService: Title
   ) {
      setTimeout(() => titleService.setTitle(this._routes.title))
   }

   ngOnInit() {
      setTimeout(() => { this._urlService.setHeaderTitle(this._routes.title) })
   }

   /*
   * private methods
   * */

   /*
   * public methods
   * */

   externalNavigate(uri: string) {
      this._hybridService.externalNavigate(uri)
   }
}
