<div class="bg-site-tertiary shadow">
   <header class="container d-flex flex-wrap justify-content-around align-items-center py-3 px-4 pb-2"
           [hidden]="hideHeader">
      <a class="mb-md-0 ms-3 me-md-auto text-dark text-decoration-none brand"
         [routerLink]="['/']">
         <img src="/assets/images/swa-logo_small.png" />
      </a>

      <div class="text-end col-lg-auto mb-lg-0 me-lg-3">
         <!--<form class="col-12 d-flex">
            <input type="text" class="form-control text-center" placeholder="email" aria-label="email">
            <input type="text" class="form-control text-center ms-2" placeholder="password" aria-label="password">
            <button class="btn btn-site-secondary fw-bold text-nowrap ms-2 ps-4 pe-4" (click)="signIn()" >SIGN IN</button>
         </form>-->
         <!--<div class="col-12 d-flex">
            <button class="btn btn-site-secondary fw-bold text-nowrap ms-2 ps-4 pe-4" (click)="signIn()">SIGN IN</button>
         </div>-->
         <button class="btn btn-site-secondary fw-bold text-nowrap ms-2 ps-4 pe-4" (click)="signIn()">SIGN IN</button>
      </div>

   </header>
</div>
