import { RouteModel } from '@appShared/site-directory.routes';

export const environment = {
   production: false,
   appStore: {
      apple: 'https://apps.apple.com/us/app/southwestern-lead/id6478800540 ',
      googlePlay: 'https://play.google.com/store/apps/details?id=com.southwestern.lead'
   },
   baseUri: 'https://southwestern-learning-web-portal-test-as.azurewebsites.net',
   brightcove: {
      accountId: 753829919001,
      experiences: {
         dashboard: '66c4f79abf6b4422547345ec',
         faq: '661436a3fd8b4685703d56bf',
         meetTheMentorsUrl: 'https://site-28869320.bcvp0rtal.com/',
         addedValuePortalUrl: 'https://site-56995380.bcvp0rtal.com'
      },
      playerId: 'KrH874wOd'
   },
   dealerApiUri: 'https://testportal.advantage4dealers.com/api/dealer',
   playerUri: 'player',
   publicCdn: {
      uri: 'https://swfclearningprod.blob.core.windows.net/dealer-training',
      areaImagesFolder: 'area-images',
      courseImagesFolder: 'course-images',
      dashboardImagesFolder: 'dashboard-images',
      resourceImagesFolder: 'resource-images',
      badgeImagesFolder: 'badge-images'
   },
   internalUris: {
      academic_resources: {
         title: 'Academic Resources',
         uri: 'https://test.advantage4teens.com',
      } as RouteModel,
      sales_portal: {
         title: 'Advantage4Dealers',
         uri: 'https://test.advantage4dealers.com'
      } as RouteModel
   },
   userPrincipalIdHeaderName: 'UserPrincipalId-29239E59E0924FC4',
   oAuthClientIdHeaderName: 'Client-Id-29239E59E0924FC4',
   oAuthClientSecretHeaderName: 'Client-Secret-29239E59E0924FC4',
   oAuthClientIdentifier: '416946437999999',
   oAuthClientSecret: 'E179C39D77D1496EB979FABB45ED5F3B',
   serviceLayerClientIdHeaderName: 'Client-Id-EE8289B486034E60',
   serviceLayerClientId: '1204683634232208',
   serviceLayerClientSecretHeaderName: 'Client-Secret-EE8289B486034E60',
   serviceLayerClientSecret: 'e91ee2fd0f634a28aa43e0223c3864bf'
}
