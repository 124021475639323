<div class="bg-site-tertiary shadow">

   <header class="container position-relative d-flex flex-wrap align-items-center justify-content-center py-3 px-4">

      <div *ngIf="user()"
           class="position-absolute d-flex user-action">
         <div class="dropdown flex-grow-1">
            <a href="#" class="d-block text-decoration-none dropdown-toggle" id="dropdownUserMenu" data-bs-toggle="dropdown" aria-expanded="false">
               <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" class="bi bi-person-circle" viewBox="0 0 16 16">
                  <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z" />
                  <path fill-rule="evenodd" d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z" />
               </svg>
               <span class="ps-2 fw-bold d-none d-sm-inline-block">{{user().displayName}}</span>
            </a>
            <ul class="dropdown-menu dropdown-menu-end text-small" aria-labelledby="dropdownUserMenu">
               <li *ngIf="user().isAdmin||user().isRep">
                  <a class="dropdown-item fw-bold" routerLink="/{{routes.content.uri}}">Home</a>
               </li>
               <li *ngIf="user().isAdmin||user().isRep">
                  <a class="dropdown-item fw-bold" routerLink="/{{routes.content.videoLibrary.uri}}">{{routes.content.videoLibrary.title}}</a>
               </li>
               <li *ngIf="user().isAdmin||user().isRep">
                  <a class="dropdown-item fw-bold" routerLink="/{{routes.content.learningJourneys.uri}}">{{routes.content.learningJourneys.title}}</a>
               </li>
               <li *ngIf="user().isAdmin||user().isRep">
                  <a class="dropdown-item fw-bold" routerLink="/{{routes.content.progress.uri}}">{{routes.content.progress.title}}</a>
               </li>
               <li *ngIf="user().isAdmin||user().isRep">
                  <a class="dropdown-item fw-bold" routerLink="/{{routes.content.socials.uri}}">{{routes.content.socials.title}}</a>
               </li>
               <li *ngIf="user().isAdmin||user().isRep">
                  <a class="dropdown-item fw-bold" routerLink="/{{routes.content.quickLinks.uri}}">{{routes.content.quickLinks.title}}</a>
               </li>
               <li><hr class="dropdown-divider"></li>
               <li><span class="dropdown-item pointable" (click)="logout()">Sign out</span></li>
            </ul>
         </div>
      </div>

      <a class="brand mb-md-0"
         routerLink="/{{user()?.isUser ? routes.content.uri : 'member'}}">
         <img src="/assets/images/swa-logo.png" class="full-logo" />
         <img src="/assets/images/swa-logo-icon.png" class="icon" />
      </a>

      <div class="position-absolute fw-bold user-points" *ngIf="!isRegistrationContext">
         <span class="fs-4">{{contactPartitionProgress()?.overallPointsEarned || 0}}</span> points
      </div>

   </header>

</div>
