export interface RouteModel {
   baseUri: string
   title: string
   uri: string
   alt_uri: string
}

export const SITE_DIRECTORY_ROUTES = {
   content: {
      learningJourneys: {
         title: 'Learning Journeys',
         baseUri: 'learning-journeys',
         uri: 'learning-journeys'
      } as RouteModel,
      progress: {
         title: 'Awards',
         baseUri: 'awards',
         uri: 'awards'
      } as RouteModel,
      quickLinks: {
         title: 'Quick Links',
         baseUri: 'quick-links',
         uri: 'quick-links'
      } as RouteModel,
      socials: {
         title: 'Socials',
         baseUri: 'socials',
         uri: 'socials'
      } as RouteModel,
      videoLibrary: {
         title: 'Added Value',
         baseUri: 'added-value',
         uri: 'added-value'
      } as RouteModel,
      title: 'SWA - Dealer Training',
      baseUri: 'learn',
      uri: 'learn'
   },
   member: {
      account: {
         billing: {
            title: 'Billing',
            baseUri: 'billing',
            uri: 'billing'
         } as RouteModel,
         membership: {
            title: 'Membership',
            baseUri: 'membership',
            uri: 'membership'
         } as RouteModel,
         notifications: {
            title: 'Notifications',
            baseUri: 'notifications',
            uri: 'notifications'
         } as RouteModel,
         profile: {
            title: 'Profile',
            baseUri: 'profile',
            uri: 'profile'
         } as RouteModel,
         progress: {
            title: 'Awards',
            baseUri: 'awards',
            uri: 'awards'
         } as RouteModel,
         users: {
            title: 'Users',
            baseUri: 'users',
            uri: 'users'
         } as RouteModel,
         title: 'Account',
         baseUri: 'account',
         uri: 'account'
      },
      setPassword: {
         title: 'Set Password',
         baseUri: 'set-password',
         uri: 'set-password'
      } as RouteModel,
      siteNotifications: {
         title: 'Notifications',
         baseUri: 'site-notifications',
         uri: 'site-notifications'
      } as RouteModel,
      title: 'Member Access',
      baseUri: 'member',
      uri: 'member'
   },
   title: 'SWA - Dealer Training',
   uri: ''
}
