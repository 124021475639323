import {
   AfterContentChecked,
   Component,
   DestroyRef,
   inject,
   Input,
   OnChanges,
   signal,
   SimpleChanges
} from '@angular/core'
import { NgIf } from '@angular/common'
import { NavigationEnd, Router, RouterLink } from '@angular/router'
import { filter } from 'rxjs'
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop'
import { UrlService } from '@appShared/services/url.service'
import { CommonService } from '@appShared/services/common.service'
import { AppFacade } from '@appShared/services/app.facade'
import { HybridWebviewInteropService } from '@appShared/services/hybrid-webview-interop.service'
import { ContentRegistrationProgressesService } from '@appShared/services/content-registration-progresses.service'
import {
   LeadSection,
   LeadSections,
   LeadSection_
} from '@appShared/services/lookup/lead-section'
import { SITE_DIRECTORY_ROUTES } from '@appShared/site-directory.routes'

@Component({
    selector: 'app-internal-header',
    templateUrl: './internal-header.component.html',
    styleUrls: ['./internal-header.component.less'],
    standalone: true,
    imports: [NgIf, RouterLink]
})
export class InternalHeaderComponent implements OnChanges, AfterContentChecked {
   @Input() leadSection: LeadSection
   headerTitle = signal<string>('')
   user = inject(AppFacade).currentUser
   isDotNetInteroperable = toSignal(inject(HybridWebviewInteropService).isDotNetInteroperable$)
   contactPartitionProgress = toSignal(inject(ContentRegistrationProgressesService).contactPartitionProgress$)
   routes = SITE_DIRECTORY_ROUTES
   isRegistrationContext: boolean
   private _headerTitleSignal = toSignal(inject(UrlService).headerTitle$)

   private _commonService = inject(CommonService)
      private _window = inject(Window)

   private _initialLeadSection = LeadSections.find(
      section => section.code === LeadSection_.Home
   )

   constructor(
      router: Router,
      destroyRef: DestroyRef
   ) {
      this._setIsRegistrationContext()
      router.events
         .pipe(
            filter(event => event instanceof NavigationEnd),
            takeUntilDestroyed(destroyRef)
         )
         .subscribe((navigationEnd: NavigationEnd) => {
            const url = navigationEnd.urlAfterRedirects || navigationEnd.url
            this._setIsRegistrationContext(url)
         })
   }

   ngOnChanges(changes: SimpleChanges): void {
      if (changes && !changes['leadSection']) {
         this.leadSection == this._initialLeadSection
      }
   }

   ngAfterContentChecked(): void {
      setTimeout(() => { this.headerTitle.set(this._headerTitleSignal()) })
   }

   /*
   * private methods
   * */

   private _setIsRegistrationContext(url?: string) {
      url = url || this._window.location.href
      this.isRegistrationContext = url?.includes('/registration?token')
   }

   /*
   * public methods
   * */

   comingSoon(areaName?: string) {
      const message = areaName ? `"${areaName}" feature is coming soon.` : ''
      this._commonService.comingSoon(message)
   }

   logout() {
      this._commonService.logout()
   }
}
