import { ChangeDetectionStrategy, Component } from '@angular/core'
import { CommonModule } from '@angular/common'
import { Title } from '@angular/platform-browser'
import { ContainerListComponent } from '@appContent/components/container-list/container-list.component'
import { SITE_DIRECTORY_ROUTES } from '@appShared/site-directory.routes'


@Component({
   selector: 'app-learning-journeys',
   standalone: true,
   imports: [CommonModule, ContainerListComponent],
   templateUrl: './learning-journeys.component.html',
   styleUrls: ['./learning-journeys.component.less'],
   changeDetection: ChangeDetectionStrategy.OnPush
})
export class LearningJourneysComponent {
   learningJourneys = SITE_DIRECTORY_ROUTES.content.learningJourneys

   constructor(
      titleService: Title
   ) {
      setTimeout(() => titleService.setTitle(`SWA - ${this.learningJourneys.title}`))
   }
}
