<div class="container socials-page">

   <div class="mx-auto mt-4">
      <div class="d-flex justify-content-center my-3 mt-0 p-3 rounded shadow-sm bg-site-tertiary text-center">
         <div class="d-flex gap-3 justifty-content-center align-items-center">
            <img class="me-3 logo d-none d-sm-inline-flex" src="/assets/images/swa-logo.png" alt="">
            <img class="me-3 logo d-inline-flex d-sm-none" src="/assets/images/favicon_57x57.png" alt="">

            <div class="lh-1 flex-grow-1 text-center">
               <h1 class="h2 mb-0 text-site-secondary lh-1">Resources &amp; Links</h1>
            </div>
         </div>

      </div>

      <div class="p-3 pt-0 background-box-opaque-rounded">


         <div class="row site-links">

            <div class="col-sm-6">

               <div class="d-flex align-items-center text-muted pt-3">
                  <a href="{{internalUris.sales_portal.uri}}" class="flex-shrink-0 me-2" target="_blank" title="Advantage 4 Dealers">
                     <img class="icon" src="/assets/images/icons/a4d-icon.png" alt="Advantage 4 Dealers">
                  </a>
                  <p class="pb-3 mb-0 small lh-sm border-bottom">
                     <a href="{{internalUris.sales_portal.uri}}" class="d-block fw-bold site-page-link" target="_blank" title="Advantage 4 Dealers">
                        Advantage4Dealers
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right" viewBox="0 0 16 16">
                           <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z" />
                        </svg>
                     </a>
                  </p>
               </div>

               <div class="d-flex align-items-center text-muted pt-3">
                  <a href="{{externalUris.click_up.uri}}" class="flex-shrink-0 me-2" target="_blank" title="Click Up">
                     <img class="icon" src="/assets/images/icons/clickup-icon.png" alt="Click Up">
                  </a>
                  <p class="pb-3 mb-0 small lh-sm border-bottom">
                     <a href="{{externalUris.click_up.uri}}" class="d-block fw-bold site-page-link" target="_blank" title="Click Up">
                        Click Up
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right" viewBox="0 0 16 16">
                           <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z" />
                        </svg>
                     </a>
                  </p>
               </div>

               <div class="d-flex align-items-center text-muted pt-3">
                  <a href="{{externalUris.media_resource_center.uri}}" class="flex-shrink-0 me-2" target="_blank" title="Media Resource Center">
                     <img class="icon" src="/assets/images/icons/mrc-icon.png" alt="Media Resource Center">
                  </a>
                  <p class="pb-3 mb-0 small lh-sm border-bottom">
                     <a href="{{externalUris.media_resource_center.uri}}" class="d-block fw-bold site-page-link" target="_blank" title="Media Resource Center">
                        Media Resource Center
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right" viewBox="0 0 16 16">
                           <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z" />
                        </svg>
                     </a>
                  </p>
               </div>

            </div>

            <div class="col-sm-6">

               <div class="d-flex align-items-center text-muted pt-3">
                  <a href="{{externalUris.team_hub.uri}}" class="flex-shrink-0 me-2" target="_blank" title="Team Hub">
                     <img class="icon" src="/assets/images/icons/team-hub-icon.png" alt="Team Hub">
                  </a>
                  <p class="pb-3 mb-0 small lh-sm border-bottom">
                     <a href="{{externalUris.team_hub.uri}}" class="d-block fw-bold site-page-link" target="_blank" title="Team Hub">
                        Team Hub
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right" viewBox="0 0 16 16">
                           <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z" />
                        </svg>
                     </a>
                  </p>
               </div>

               <div class="d-flex align-items-center text-muted pt-3">
                  <a href="{{externalUris.southwestern_advantage.uri}}" class="flex-shrink-0 me-2" target="_blank" title="Southwestern Advantage Home">
                     <img class="icon" src="/assets/images/icons/swa-icon.png" alt="Southwestern Advantage Home">
                  </a>
                  <p class="pb-3 mb-0 small lh-sm border-bottom">
                     <a href="{{externalUris.southwestern_advantage.uri}}" class="d-block fw-bold site-page-link" target="_blank" title="Southwestern Advantage Home">
                        Southwestern Advantage Home
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right" viewBox="0 0 16 16">
                           <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z" />
                        </svg>
                     </a>
                  </p>
               </div>

               <div class="d-flex text-muted pt-3 ps-2 align-items-center">
                  <span (click)="logout()"
                        class="flex-shrink-0 me-2 pointable" title="Sign Out" style="color: #0484ba">
                     <img class="icon" src="/assets/images/icons/sign-out.png" alt="Sign Out">
                  </span>

                  <p class="mb-0 small lh-sm">
                     <span (click)="logout()"
                           class="d-block fw-bold site-page-link pointable" title="Sign Out">
                        Sign Out
                     </span>
                  </p>
               </div>

            </div>

         </div>

      </div>

   </div>


</div>

