import { ContentShellComponent } from './content-shell/content-shell.component'
import { CourseLibraryComponent } from './course-library/course-library.component'
import { DashboardComponent } from './dashboard/dashboard.component'
import { LearningJourneysComponent } from './learning-journeys/learning-journeys.component'
import { ProgressComponent } from './progress/progress.component'
import { ResourcesComponent } from './resources/resources.component'
import { SocialsComponent } from './socials/socials.component'
import { VideoLibraryComponent } from './video-library/video-library.component'

export const containers: any[] = [
   ContentShellComponent,
   CourseLibraryComponent,
   DashboardComponent,
   LearningJourneysComponent,
   ProgressComponent,
   ResourcesComponent,
   SocialsComponent,
   VideoLibraryComponent
];

export * from './content-shell/content-shell.component'
export * from './course-library/course-library.component'
export * from './dashboard/dashboard.component'
export * from './learning-journeys/learning-journeys.component'
export * from './progress/progress.component'
export * from './resources/resources.component'
export * from './socials/socials.component'
export * from './video-library/video-library.component'
