//import { ProdAdminTestingComponent } from './prod-admin-testing/prod-admin-testing.component'
//import { ProductAdminShellComponent } from './product-admin-shell/product-admin-shell.component'
//import { ItemSearchComponent } from './item-search/item-search.component'
//import { ProductDetailComponent } from './product-detail/product-detail.component'
//import {
//  ItemEditComponent,
//  ItemEditModalComponent,
//  ItemEditBasicDataComponent,
//  ItemEditBundleComponent,
//  ItemEditCategoryComponent,
//  ItemEditFileComponent,
//  ItemEditTextComponent,
//  ItemEditNoteComponent,
//  ItemEditFulfillmentPartnerComponent,
//  ItemStatusComponent,
//  ItemEditPublisherShellComponent,
//  ItemEditPublisherBasicComponent,
//  ItemEditPublisherGridComponent
//} from './item-edit'
//import {
//  ProductAuthorizationEditComponent,
//  ProductAuthorizationGridComponent,
//  ProductAuthorizationShellComponent,
//  ProductLineItemAutoRenewalComponent,
//  ProductLineItemBasicComponent,
//  ProductLineItemsGridComponent,
//  ProductLineItemShellComponent
//} from './product-line-item'

import { MemberShellComponent } from './member-shell/member-shell.component';
import { ResetPasswordShellComponent } from './reset-password-shell/reset-password-shell.component';

export const containers: any[] = [
   MemberShellComponent,
   ResetPasswordShellComponent
   //ItemSearchComponent,
   //ProductDetailComponent,
   //ItemEditComponent,
   //ItemEditModalComponent,
   //ItemEditBundleComponent,
   //ItemEditCategoryComponent,
   //ItemEditFileComponent,
   //ItemEditTextComponent,
   //ItemEditNoteComponent,
   //ItemEditBasicDataComponent,
   //ItemEditFulfillmentPartnerComponent,
   //ItemEditPublisherShellComponent,
   //ItemEditPublisherBasicComponent,
   //ItemEditPublisherGridComponent,
   //ItemStatusComponent,
   //ProductLineItemShellComponent,
   //ProductLineItemsGridComponent,
   //ProductLineItemBasicComponent,
   //ProductAuthorizationEditComponent,
   //ProductAuthorizationGridComponent,
   //ProductAuthorizationShellComponent,
   //ProductLineItemAutoRenewalComponent,
   //ProdAdminTestingComponent
];

export * from './member-shell/member-shell.component';
export * from './reset-password-shell/reset-password-shell.component';
//export * from './prod-admin-testing/prod-admin-testing.component'
//export * from './product-admin-shell/product-admin-shell.component'
//export * from './item-search/item-search.component'
//export * from './product-detail/product-detail.component'
//export * from './item-edit'
//export * from './product-line-item'
