import { ChangeDetectionStrategy, Component, inject, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Title } from '@angular/platform-browser'
import { SITE_DIRECTORY_ROUTES } from '@appShared/site-directory.routes'
import { EXTERNAL_URIS } from '@appShared/external.uris'
import { environment } from '@appEnvironments/environment';

@Component({
  selector: 'app-socials',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './socials.component.html',
  styleUrls: ['./socials.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SocialsComponent implements OnInit {
   private _titleService = inject(Title)
   socialLinkTitle = SITE_DIRECTORY_ROUTES.content.socials.title
   socialImageUri = `${environment.publicCdn.uri}/${environment.publicCdn.resourceImagesFolder}/social.jpg`
   external_uris = EXTERNAL_URIS


   ngOnInit(): void {
      setTimeout(() => this._titleService.setTitle(`SWA - ${this.socialLinkTitle}`))
   }
}
