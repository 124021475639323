<ng-container *ngIf="isLoaded && modules.length">

   <div class="modules-wrapper justify-content-center">

      <div class="modules">

         <div *ngFor="let module of modules; trackBy: trackByModuleId"
              (click)="goToRoute(module)"
              class="module pointable">

            <div class="position-relative w-100">
               <img [src]="module.iconImageUri" class="w-100" />
               <!-- top/start/bottom/end 0 in case we want a color overlay for whole image -->
               <div *ngIf="!module.isAreaContainer" class="position-absolute top-0 start-0 bottom-0 end-0 position-relative">
                  <div *ngIf="module.statusImageUri"
                       class="course-status position-absolute">
                     <img [src]="module.statusImageUri" class="w-100" />
                  </div>
               </div>
            </div>

         </div>

      </div>

   </div>

</ng-container>

<div *ngIf="isLoaded && !modules.length" class="fs-1 mt-3 d-flex justify-content-center">
   <img src="/assets/images/coming-soon.jpg" class="img-fluid rounded" alt="Content Coming Soon" />
</div>

<ng-container *ngIf="parentContainerId">

   <div class="d-flex justify-content-center align-items-center mt-4 gap-1">
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-left" viewBox="0 0 16 16">
         <path fill-rule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8" />
      </svg>
      <a routerLink="/{{learningJourneyUri}}" class="d-block fw-bold site-page-link text-underline pointable" title="Learning Journeys">
         Back to Learning Journeys
      </a>
   </div>

</ng-container>
