import { Injectable, Inject, SecurityContext, inject } from '@angular/core'
import { formatCurrency, formatNumber } from '@angular/common'
import {
   FormGroup,
   FormControl,
   AbstractControl,
   FormArray,
   ValidationErrors
} from '@angular/forms'
import { HttpHeaders, HttpParams } from '@angular/common/http'
import { DatePipe, DOCUMENT, ViewportScroller } from '@angular/common'
import { ActivatedRouteSnapshot } from '@angular/router'
import {
   DomSanitizer,
   SafeHtml,
   SafeStyle,
   SafeScript,
   SafeUrl,
   SafeResourceUrl
} from '@angular/platform-browser'
import { BehaviorSubject, Observable, of } from 'rxjs'
import {
   ConfirmOptions,
   ConfirmService
} from '@appShared/components/confirm-modal-and-service'
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker/bs-datepicker.config'
// https://www.c-sharpcorner.com/article/client-side-pdf-generation-in-angular-with-pdfmake/
//import pdfMake from 'pdfmake/build/pdfmake';
//import pdfFonts from 'pdfmake/build/vfs_fonts';

import {
   ToastrType,
   ToastrPosition
} from './toastr.service'
import { ToastrService, GlobalConfig } from 'ngx-toastr'
import { DateTimeService } from '@appShared/services/date-time.service'
import * as CryptoJS from 'crypto-js'
//import { EmbedVideoService } from 'ngx-embed-video';
import * as _ from 'lodash'
import { WindowService } from './window.service'

export interface IFormControlError {
   control: string
   errorType: string
   errorValue: any
}

export enum PdfCreateAction_ {
   download = 1,
   open,
   print
}

@Injectable({ providedIn: 'root' })
export class CommonService {
   private _loading = new BehaviorSubject<boolean>(false);
   loading$ = this._loading.asObservable();
   loading?: boolean
   datePickerBaseConfig: Partial<BsDatepickerConfig> = {
      containerClass: 'theme-orange',
      showWeekNumbers: false
      // isAnimated: true
   };
   multiUseGuid = '67f220fa-3592-407e-9dde-300a734a182d';
   postRegistrationQueryParam = 'post-reg'

   private _window = inject(WindowService).nativeWindow
   private $: any = this._window['$'] as any
   private _document: Document = inject(DOCUMENT)

   constructor(
      public dateTime: DateTimeService,
      private _sanitizer: DomSanitizer,
      private _viewportScroller: ViewportScroller,
      private _confirmService: ConfirmService,
      private _toastr: ToastrService
   ) { }

   setLoading(loading?: boolean): void {
      this._loading.next(loading || false)
   }

   /* eslint-disable  */
   regexp() {
      return {
         cvv: /^[0-9]{3,4}$/,
         dealerAccount: /^11[0-9]{6}$/,
         dob: /^(((0?[1-9]|1[012])\/(0?[1-9]|1\d|2[0-8])|(0?[13456789]|1[012])\/(29|30)|(0?[13578]|1[02])\/31)\/(19|[2-9]\d)\d{2}|0?2\/29\/((19|[2-9]\d)(0[48]|[2468][048]|[13579][26])|(([2468][048]|[3579][26])00)))$/,
         email: /^((([A-Za-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([A-Za-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([A-Za-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([A-Za-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([A-Za-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([A-Za-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([A-Za-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([A-Za-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([A-Za-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([A-Za-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?$/,
         imageFileName: /^.*\/.*.(png|jpg)$/,
         notAPOBox: /^(?!(box[^a-z]|(p[-. ]?o.?[- ]?|post office )b(.|ox)))/i,
         phone: {
            usOnly: /^(\(?(\d{3})\)?[- .]?(\d{3})[- .]?(\d{4}))|(\+?(011(9[976]\d|8[987530]\d|6[987]\d|5[90]\d|42\d|3[875]\d|2[98654321]\d|9[8543210]|8[6421]|6[6543210]|5[87654321]|4[987654310]|3[9643210]|2[70]|7|1)\d{0,14}))$/,
            usAndCAOnly: /^$|(\(?(\d{3})\)?[- .]?(\d{3})[- .]?(\d{4}))|(\+?(011(9[976]\d|8[987530]\d|6[987]\d|5[90]\d|42\d|3[875]\d|2[98654321]\d|9[8543210]|8[6421]|6[6543210]|5[87654321]|4[987654310]|3[9643210]|2[70]|7|1)\d{0,14}))$/,
            usAndInternational: /^[+]?([0-9]*[\.\s\-\(\)]|[0-9]+){3,24}$/
         },
         sapSourceId: /^\d{8}$/,
         sevisId: /^N\d{10}$/,
         ssn: /^(?!(111111111))(?!(111-11-1111))((?!(000|666|9))\d{3}(-)?(?!00)\d{2}(-)?(?!0000)\d{4})|\d{3}-\*\*-\*\*\*\*$/,
         /*ssn: /^(?!(000|666|9))\d{3}(-)?(?!00)\d{2}(-)?(?!0000)\d{4}|\d{3}-\*\*-\*\*\*\*$/, */
         password: /^(?=.*?[a-zA-Z])(?=.*?\d)(?=.*?[#?!@$%^&_*-]).{8,25}$/,
         passwordMessage:
            'Passwords must be at least 8 characters long, contain one digit and one of the following: #, _, *, &, %, ?, or !',
         postalCode: {
            usOnly: /^\d{5}(?:[-\s]\d{4})?$/,
            caOnly: /^[ABCEGHJKLMNPRSTVXYabceghjklmnprstvxy]\d[A-Za-z] ?\d[A-Za-z]\d$/
         },
         url: /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/i,
         username: /^[a-zA-Z0-9]{4,16}$/,
         usernameMessage:
            'Usernames must be at between 4 to 16 characters long, containing any combination of numbers and letters',
         supportedVideoUrl: /^(https?:\/\/)?(www\.)?(vimeo\.com|youtube\.com|youtu\.be|dailymotion\.com|dai\.ly)\//i
      }
   }

   mask() {
      return {
         creditCard: [
            /\d/,
            /\d/,
            /\d/,
            /\d/,
            '-',
            /\d/,
            /\d/,
            /\d/,
            /\d/,
            '-',
            /\d/,
            /\d/,
            /\d/,
            /\d/,
            '-',
            /\d/,
            /\d/,
            /\d/,
            /\d/
         ],
         creditCardExp: [/0|1/, /[0-9]/, '/', /\d/, /\d/],
         phone: [
            '(',
            /[1-9]/,
            /\d/,
            /\d/,
            ')',
            ' ',
            /\d/,
            /\d/,
            /\d/,
            '-',
            /\d/,
            /\d/,
            /\d/,
            /\d/
         ],
         username: [
            /[a-zA-Z0-9]/, /[a-zA-Z0-9]/, /[a-zA-Z0-9]/, /[a-zA-Z0-9]/,
            /[a-zA-Z0-9]/, /[a-zA-Z0-9]/, /[a-zA-Z0-9]/, /[a-zA-Z0-9]/,
            /[a-zA-Z0-9]/, /[a-zA-Z0-9]/, /[a-zA-Z0-9]/, /[a-zA-Z0-9]/,
            /[a-zA-Z0-9]/, /[a-zA-Z0-9]/, /[a-zA-Z0-9]/, /[a-zA-Z0-9]/
         ]
      }
   }

   httpOptions(): Object {
      // https://stackoverflow.com/a/71961946/1146117

      let headers = new HttpHeaders()
         .set('Content-Type', 'application/json; charset=utf-8')

      return { headers }
   }

   // https://medium.com/javascript-in-plain-english/the-right-way-to-make-api-calls-in-angular-5cc03a62bf43
   handleError<T>(operation = 'operation', result?: T) {
      return (error: any): Observable<T> => {
         this.messageUser(
            `Error: ${error.message}`,
            'Error Occurred',
            ToastrType.error
         )
         this.logError(error.message)
         this.setLoading(false)
         return of(result as T)
      }
   }

   messageUser(
      msg: string,
      title?: string,
      toastrType?: ToastrType,
      options?: any
   ) {
      toastrType = toastrType || ToastrType.success

      options = {
         closeButton: false,
         debug: false,
         newestOnTop: false,
         progressBar: false,
         positionClass: ToastrPosition.topFullWidth,
         preventDuplicates: false,
         onclick: null,
         enableHtml: true,
         showDuration: '300',
         hideDuration: '1000',
         timeOut: '5000',
         extendedTimeOut: '1000',
         showEasing: 'swing',
         hideEasing: 'linear',
         showMethod: 'slideDown' /*fadeIn*/,
         hideMethod: 'fadeOut',
         ...options
      } as GlobalConfig

      switch (toastrType) {
         case ToastrType.success: {
            this._toastr.success(msg, title, options)
            break
         }
         case ToastrType.error: {
            this._toastr.error(msg, title, options)
            break
         }
         case ToastrType.info: {
            this._toastr.info(msg, title, options)
            break
         }
         case ToastrType.warning: {
            this._toastr.warning(msg, title, options)
            break
         }

         default: {
            // silent log
            console.log(
               `toastr, but unknown ToastrType - msg:${msg} - title: ${title}`
            )
            break
         }
      }
   }

   comingSoon(message?: string, title?: string, toastrType?: ToastrType) {
      message = message || 'This feature is coming soon!'
      title = title || 'Coming Soon!'
      toastrType = toastrType || ToastrType.info
      this.messageUser(
         message,
         title,
         toastrType
      )
   }

   dialogOK(options: ConfirmOptions) {
      if (options.message) {
         this._confirmService
            .confirm({
               yesText: 'OK',
               hideNo: true,
               ...options
            })
            .then(() => {
               /* Do Nothing */
            })
            .catch(() => {
               /* Do Nothing */
            })
      }
   }

   logError(error, requestMethod?, payload?) {
      if (error) {
         if (_.isObject(error)) {
            delete error['headers']
            delete error['ok']
            delete error['name']
         }

         this._window.sw_lead.errors.push({
            __method: requestMethod,
            _timestamp: new Date(),
            error,
            payload
         })
      }
   }

   execute(callback, params?) {
      if (callback && _.isFunction(callback)) {
         callback(params)
      }
   }

   encrypt(val: any, key?: string): string {
      if (!val) return null

      val = _.isObject(val) ? JSON.stringify(val) : val.toString()

      return CryptoJS.AES.encrypt(val, key || this.multiUseGuid).toString()

      /* use:
         const encrypted = this._commonService.encrypt(this._subscriptionId)
         console.log('encrypted:', encrypted)
         const decrypted = this._commonService.decrypt(encrypted)
         console.log('decrypted:', decrypted)
      */
   }

   decrypt(cipherText: string, key?: string): string {
      if (!cipherText) return null

      var bytes = CryptoJS.AES.decrypt(cipherText, key || this.multiUseGuid)
      try {
         return bytes.toString(CryptoJS.enc.Utf8)
      } catch {
         return null
      }
   }

   cleanModelData(options) {
      const pipe = new DatePipe('en-US')
      let returnModel

      options = _.extend(
         {
            model: null,
            massageDataFunction: null,
            propertiesToRemove: null,
            /* default removeNullValueProperties conversion to true*/
            removeNullValueProperties: true,
            /* default date-to-string conversion to true*/
            datesToStrings: true
         },
         options
      )

      const cleanObject = data => {
         // disregard any File objects
         if (data instanceof File) {
            return data
         }

         // remove angular-only properties
         data = _.omit(
            data,
            _.extend(options.propertiesToRemove, ['$$hashKey'])
         )

         for (const property in data) {
            if (data.hasOwnProperty(property)) {
               const item = data[property]

               if (options.removeNullValueProperties && !item) {
                  delete data[property]
               } else {
                  if (_.isArray(item)) {
                     const newArrayItems = []
                     // recursively "clean" each property
                     for (let arrayItem, i = -1; (arrayItem = item[++i]);) {
                        if (_.isFunction(arrayItem) || _.isObject(arrayItem)) {
                           newArrayItems.push(cleanObject(arrayItem))
                        } else {
                           newArrayItems.push(arrayItem)
                        }
                     }
                     data[property] = newArrayItems
                  } else if (_.isFunction(item)) {
                     // remove all functions
                     delete data[property]
                  } else if (options.datesToStrings && _.isDate(item)) {
                     // set Date objects as strings

                     const stringDate = pipe.transform(item, 'short') // $filter('date')(item, service.dayFormat);
                     data[property] = stringDate
                  } else if (_.isObject(item)) {
                     const copiedObject = JSON.parse(JSON.stringify(item))
                     data[property] = cleanObject(copiedObject)
                  }
               }
            }
         }

         return data
      }

      if (_.isObject(options.model)) {
         // make copy as not to affect controller model
         options.model = JSON.parse(JSON.stringify(options.model))

         // perform any massage-data functions prior to cleaning
         if (_.isFunction(options.massageDataFunction)) {
            options.massageDataFunction(options.model)
         }

         // clone and remove any properties passed for deletion
         returnModel = _.omit(options.model, options.propertiesToRemove)

         returnModel = cleanObject(returnModel)
      }

      return returnModel
   }

   sanitize(url: string) {
      return this._sanitizer.bypassSecurityTrustUrl(url)
   }

   transform(
      value: any,
      securityContext: SecurityContext
   ): SafeHtml | SafeStyle | SafeScript | SafeUrl | SafeResourceUrl {
      switch (securityContext) {
         case SecurityContext.HTML:
            return this._sanitizer.bypassSecurityTrustHtml(value)
         case SecurityContext.STYLE:
            return this._sanitizer.bypassSecurityTrustStyle(value)
         case SecurityContext.SCRIPT:
            return this._sanitizer.bypassSecurityTrustScript(value)
         case SecurityContext.URL:
            return this._sanitizer.bypassSecurityTrustUrl(value)
         case SecurityContext.RESOURCE_URL:
            return this._sanitizer.bypassSecurityTrustResourceUrl(value)
         default:
            throw new Error(`Invalid safe type specified: ${securityContext}`)
      }
   }

   format(fmt, ...args) {
      if (
         !fmt.match(/^(?:(?:(?:[^{}]|(?:\{\{)|(?:\}\}))+)|(?:\{[0-9]+\}))+$/)
      ) {
         throw new Error('invalid format string.')
      }
      return fmt.replace(
         /((?:[^{}]|(?:\{\{)|(?:\}\}))+)|(?:\{([0-9]+)\})/g,
         (m, str, index) => {
            if (str) {
               return str.replace(/(?:{{)|(?:}})/g, chr => m[0])
            } else {
               if (index >= args.length) {
                  throw new Error('argument index is out of range in format')
               }
               return args[index]
            }
         }
      )
   }

   //embedVideo(videoUrl, options?: any) {
   //   //https://www.npmjs.com/package/ngx-embed-video
   //   return this._embedVideoService.embed(videoUrl, options);
   //}

   logApi(url, requestMethod?, data?, payload?) {
      this._window.sw_lead.api_history.push({
         __method: requestMethod,
         _api: url,
         _timestamp: new Date(),
         data,
         payload
      })
   }

   navigateToLogin(returnUrl?: string) {
      let authSignin = '/auth/signin'

      if (returnUrl) {
         returnUrl = encodeURIComponent(returnUrl)
         authSignin = `${authSignin}?returnUrl=${returnUrl}`
      }

      window.location.replace(authSignin)
   }

   logout(redirectUrl?: string) {
      let authSignout = '/auth/signout'

      if (redirectUrl) {
         redirectUrl = encodeURIComponent(redirectUrl)
         authSignout = `${authSignout}?redirectUrl=${redirectUrl}`
      }

      window.location.replace(authSignout)
   }

   scrollToPosition(position?: any) {
      position = position || [0, 0]
      this._viewportScroller.scrollToPosition(position)
   }

   scrollToAnchor(id: string) {
      if (id) {
         this._viewportScroller.scrollToAnchor(id)
      }
   }

   scrollTo(el: Element): void {
      if (el) {
         el.scrollIntoView({ behavior: 'smooth', block: 'center' })
      }
   }

   // https://www.bennadel.com/blog/3586-scrolling-an-overflow-container-back-to-the-top-on-content-change-in-angular-7-2-7.htm
   scrollToModalAnchor(id: string) {
      /* using base javascript method to handle modals */
      const elem = this._document.getElementById(`${id}`)
      if (elem) {
         elem.scrollIntoView({
            behavior: 'smooth'
         })
      }
   }

   scrollToError(): void {
      const firstElementWithError = this._document.querySelector(
         '.ng-invalid[formControlName]'
      )
      if (firstElementWithError) {
         this.scrollTo(firstElementWithError)
      }
   }

   setFormControlSelect(
      inputField: number,
      arraySet: any[],
      selectFormControl: FormControl,
      matchingFieldOverrideName?: string,
      allowZero?: boolean
   ) {
      matchingFieldOverrideName = matchingFieldOverrideName || 'code'

      if (
         (inputField || (inputField === 0 && allowZero))
         && (arraySet?.length)
         && selectFormControl
      ) {
         const matchingEntity = arraySet.find(item => {
            return item[matchingFieldOverrideName] === inputField
         })
         if (matchingEntity) {
            selectFormControl.setValue(matchingEntity)
         }
      }
   }

   getMatchingEntity(
      arraySet: any[],
      inputField: number,
      matchingFieldOverrideName?: string,
      allowFalsyValue?: boolean
   ) {
      matchingFieldOverrideName = matchingFieldOverrideName || 'code'

      if (inputField || allowFalsyValue) {
         return arraySet.find(item => {
            return item[matchingFieldOverrideName] === inputField
         })
      }
   }

   /**
    * This class written as helper to support this feature:
    * https://egghead.io/lessons/angular-create-a-formcontrol-dynamically-with-reactive-forms-in-angular
    */
   setDynamicFormControlSelect(
      classInstance: any,
      selectPropertyName: string,
      control: AbstractControl,
      arrayset: any[],
      inputField: number,
      matchingFieldOverrideName?: string,
      allowFalsyValue?: boolean
   ) {
      control.setValue(inputField)

      if (inputField || allowFalsyValue) {
         const matchingEntity = this.getMatchingEntity(
            arrayset,
            inputField,
            matchingFieldOverrideName,
            allowFalsyValue
         )
         if (matchingEntity) {
            classInstance[selectPropertyName] = matchingEntity
         }
      }
   }

   setNumber(val: number, disallowNull?: boolean): number {
      return isNaN(+val) ? (disallowNull ? 0 : null) : val
   }

   standardDomainSearchFn(
      term: string,
      item: any,
      matchingFieldOverride1?: string,
      matchingFieldOverride2?: string
   ): boolean {
      matchingFieldOverride1 = matchingFieldOverride1 || 'shortDescription'
      matchingFieldOverride2 = matchingFieldOverride2 || 'description'
      term = term.toLocaleLowerCase()
      return (
         item[matchingFieldOverride1].toLocaleLowerCase().indexOf(term) > -1 ||
         item[matchingFieldOverride2].toLocaleLowerCase().indexOf(term) > -1
      )
   }
   /**
    * getResolvedUrl(route) => /project/12345
    * @param route
    */
   getResolvedUrl(route: ActivatedRouteSnapshot): string {
      let resolvedUrl = route.pathFromRoot
         .map(v => v.url.map(segment => segment.toString()).join('/'))
         .join('/')

      return resolvedUrl.replace(/\/\//g, '/')
   }

   /**
    * getConfiguredUrl(route) => /project/:id
    * @param route
    */
   getConfiguredUrl(route: ActivatedRouteSnapshot): string {
      return (
         '/' +
         route.pathFromRoot
            .filter(v => v.routeConfig)
            .map(v => v.routeConfig!.path)
            .join('/')
      )
   }

   getHttpParamsFromObject(paramObject: any): HttpParams {
      let params = new HttpParams()

      if (_.isObject(paramObject)) {
         Object.keys(paramObject).forEach(key => {
            const field = paramObject[key]
            const appendParam = val => {
               params = params.append(key, val)
            }
            if (field || field === false || field === 0) {
               if (_.isArray(field)) {
                  field.forEach(val => appendParam(val))
               } else {
                  appendParam(field)
               }
            }
         })
      }

      return params
   }

   getFormValidationErrors(form: AbstractControl): IFormControlError[] {
      const errors = new Array<IFormControlError>()
      if (form && form instanceof FormGroup) {
         Object.keys(form.controls).forEach(key => {
            const controlErrors: ValidationErrors = form.get(key).errors
            if (controlErrors != null) {
               Object.keys(controlErrors).forEach(controlError => {
                  errors.push({
                     control: key,
                     errorType: controlError,
                     errorValue: controlErrors[controlError]
                  } as IFormControlError)
               })
            }
         })
      }

      return errors
   }

   // https://gist.github.com/alexrdz/3d0c9aef63a24b144614598c011f107a
   /**
    * returns AbstractControl names array for debugging
    */
   findInvalidFormControls(form: AbstractControl) {
      const invalid = []

      if (form && form instanceof FormGroup) {
         const controls = form.controls
         for (const name in controls) {
            if (controls[name].invalid) {
               invalid.push(name)
            }
         }
      }

      return invalid
   }

   /**
    * same as above (findInvalidFormControls),
    * but returns AbstractControl[] (more verbose)
    */
   findInvalidControlObjects(
      _input: AbstractControl,
      _invalidControls?: AbstractControl[]
   ) {
      if (!_invalidControls) {
         _invalidControls = []
      }

      if (!(_input instanceof FormArray) && !(_input instanceof FormGroup)) {
         return _invalidControls
      }

      const controls = _input.controls
      for (const name in controls) {
         if (controls.hasOwnProperty(name)) {
            const control = controls[name]
            if (control.invalid) {
               _invalidControls.push(control)
            }
            switch (control.constructor.name) {
               case 'FormArray':
                  (control as FormArray).controls.forEach(
                     _control =>
                     (_invalidControls = this.findInvalidControlObjects(
                        _control,
                        _invalidControls
                     ))
                  )
                  break

               case 'FormGroup':
                  _invalidControls = this.findInvalidControlObjects(
                     control,
                     _invalidControls
                  )
                  break
            }
         }
      }

      return _invalidControls
   }

   /* http://pdfmake.org/playground.html */
   //generatePdf(
   //   docDefinition: any,
   //   action: PdfCreateAction_ = PdfCreateAction_.open
   //) {
   //   pdfMake.vfs = pdfFonts.pdfMake.vfs;

   //   docDefinition = docDefinition || {
   //      header: '!! NO DOCUMENT DEFINITION !!',
   //      content: 'Sample PDF generated - NEED Document Definition'
   //   };

   //   if (action === PdfCreateAction_.download) {
   //      pdfMake.createPdf(docDefinition).download();
   //   } else if (action === PdfCreateAction_.print) {
   //      pdfMake.createPdf(docDefinition).print();
   //   } else {
   //      pdfMake.createPdf(docDefinition).open();
   //   }
   //}

   formatCurrency(
      value: number,
      digitInfo?: string,
      disregardParens?: boolean
   ) {
      const formattedCurrency = formatCurrency(
         value,
         'en',
         '$',
         'USD',
         digitInfo
      )

      // wrap parens for negative value
      return value < 0 && !disregardParens
         ? `(${formattedCurrency.replace('-', '')})`
         : formattedCurrency
   }

   formatNumber(value: number, digitInfo?: string, disregardParens?: boolean) {
      const formattedNumber = formatNumber(value, 'en', digitInfo)

      return value < 0 && !disregardParens
         ? `(${formattedNumber.replace('-', '')})`
         : formattedNumber
   }
   /* eslint-enable */
}
