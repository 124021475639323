import { Injectable } from '@angular/core';
import { IDomainModel } from './[CodeGen]/domain-model.interface';
import { Lookups } from './[CodeGen]';
import * as _ from 'lodash';

@Injectable({ providedIn: 'root' })
export class LookupService {
   static instance: LookupService;
   private _lookups: IDomainModel[][];

   constructor() {
      this._lookups = Lookups;
      LookupService.instance = this;
   }

   toDomainModel<T extends IDomainModel, C extends number>(
      t: new () => T,
      code: C
   ): T {
      const proto: T = new t();
      const domainModels = this._lookups.find(
         dm => dm[0].typeName === proto.constructor.name
      );

      const domainModel = domainModels.find(l => l.code === code);

      return domainModel as T;
   }

   toDomainModels<T extends IDomainModel>(t: new () => T): T[] {
      const proto: T = new t();
      const domainModels = this._lookups.find(
         dm => dm[0].typeName === proto.constructor.name
      );

      return domainModels as T[];
   }
}
