import { ChangeDetectionStrategy, Component, OnInit, inject } from '@angular/core'
import { NgFor, NgClass } from '@angular/common'
import { Title } from '@angular/platform-browser'
import { AchievementsService } from '@appShared/services/achievements.service'
import { HybridWebviewInteropService } from '@appShared/services/hybrid-webview-interop.service'
import { MentorBadgesComponent } from '@appContent/components/mentor/badges/badges.component'
import { SITE_DIRECTORY_ROUTES } from '@appShared/site-directory.routes'
import { EXTERNAL_URIS } from '@appShared/external.uris'
import { environment } from '@appEnvironments/environment'

@Component({
    selector: 'app-content-progress',
    templateUrl: './progress.component.html',
    styleUrls: ['./progress.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [NgFor, NgClass, MentorBadgesComponent]
})
export class ProgressComponent implements OnInit {
   private _achievementsService = inject(AchievementsService)
   private _hybridService = inject(HybridWebviewInteropService)
   private _titleService = inject(Title)

   comingSoonImageUri = `${environment.publicCdn.uri}/${environment.publicCdn.badgeImagesFolder}/coming-soon.png`
   contestImageUri = `${environment.publicCdn.uri}/${environment.publicCdn.badgeImagesFolder}/contest.png?=${(new Date()).getTime()}`
   contestUri = EXTERNAL_URIS.contest.uri
   contentRoute = SITE_DIRECTORY_ROUTES.content
   contentRootUri = this.contentRoute.uri

   /* Achievements Signals */
   contactCourseAchievements = this._achievementsService.contactCourseAchievements
   //contactMentorAchievements = this._achievementsService.contactMentorAchievements

   ngOnInit() {
      setTimeout(() => this._titleService.setTitle(`SWA - ${this.contentRoute.progress.title}`))

      this._achievementsService.refreshContactAchievements()
   }

   externalNavigate(uri: string) {
      //this._hybridService.externalNavigate(uri)
   }
}
