<div class="container">

   <div class="mt-3">

      <h2 class="d-flex justify-content-center">
         <span class="fw-bold text-uppercase text-site-secondary">{{contentRoute.progress.title}}</span>
      </h2>

      <hr class="mb-3" />

      <!--<div class="row mb-5">

         <div class="col-12 text-center">

            <img [src]="comingSoonImageUri" title="Coming Soon" class="img-link w-100" />

         </div>

      </div>-->

      <!--<div class="row mb-5">

         <div class="col-12 text-center">

            <div (click)="externalNavigate(contestUri)" class="pointable">
               <img [src]="contestImageUri" title="Vision Board Contest" class="img-link w-100 pointable" />
            </div>

         </div>

      </div>-->

      <h2 class="ps-2">Courses</h2>

      <div class="d-flex justify-content-center flex-wrap badges">

         <ng-template ngFor let-courseAchievement [ngForOf]="contactCourseAchievements()">

            <div class="badge pointable d-flex flex-column">

                <!--might allow to go to the course in the future:
                (click)=goToRoute(courseAchievement)-->

               <img src="{{courseAchievement.imageSourceUri}}?v=24.10.04" class="card-img" />
               <div class="badge-name text-wrap pt-2"
                    [ngClass]="{'text-site-secondary': courseAchievement.achievementEarned}">{{courseAchievement.achievementName}}</div>
            </div>

         </ng-template>

      </div>

      <!--<div class="mt-5 mb-3">

         <h2 class="ps-2">Mentor Meetings</h2>

         <app-mentor-badges [contactMentorAchievements]="contactMentorAchievements"></app-mentor-badges>

      </div>-->

   </div>

</div>

