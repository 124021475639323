import { ChangeDetectionStrategy, Component, inject } from '@angular/core'
import { Router } from '@angular/router'
import { Title } from '@angular/platform-browser'
import { HybridWebviewInteropService } from '@appShared/services/hybrid-webview-interop.service'
import { CommonService } from '@appShared/services/common.service'
import { SITE_DIRECTORY_ROUTES } from '@appShared/site-directory.routes'
import { EXTERNAL_URIS } from '@appShared/external.uris'
import { environment } from '@appEnvironments/environment'

@Component({
    selector: 'app-content-resources',
    templateUrl: './resources.component.html',
    styleUrls: ['./resources.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true
})
export class ResourcesComponent {
   quickLinks = SITE_DIRECTORY_ROUTES.content.quickLinks
   quickLinksTitle = this.quickLinks.title
   resourceImagesFolder = `${environment.publicCdn.uri}/${environment.publicCdn.resourceImagesFolder}`
   externalUris = EXTERNAL_URIS
   internalUris = environment.internalUris

   private _hybridService = inject(HybridWebviewInteropService)
   private _router = inject(Router)
   private _commonService = inject(CommonService)

   constructor(
      titleService: Title
   ) {
      setTimeout(() => titleService.setTitle(`SWA - ${this.quickLinksTitle}`))
   }

   /*
   * private methods
   * */

   /*
   * public methods
   * */

   externalNavigate(uri: string) {
      this._hybridService.externalNavigate(uri)
   }

   navigate(uri) {
      if(uri) this._router.navigate([uri])
   }

   logout() {
      this._commonService.logout()
   }
}
