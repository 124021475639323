import { ChangeDetectionStrategy, Component, inject } from '@angular/core'
import { NgIf } from '@angular/common'
import { ActivatedRoute } from '@angular/router'
import { Title } from '@angular/platform-browser'
import { ContainersService } from '@appContent/shared/services/containers.service'
import { ContainerListComponent } from '@appContent/components/container-list/container-list.component'

@Component({
   selector: 'app-content-course-library',
   templateUrl: './course-library.component.html',
   styleUrls: ['./course-library.component.less'],
   changeDetection: ChangeDetectionStrategy.OnPush,
   standalone: true,
   imports: [NgIf, ContainerListComponent]
})
export class CourseLibraryComponent {
   private _activatedRoute = inject(ActivatedRoute)
   private _snapshot = this._activatedRoute.snapshot
   private _containersService = inject(ContainersService)

   parentContainerId = +(this._snapshot.paramMap.get('parentContainerId') || 0)
   selectedJourney = inject(ContainersService).selectedJourney

   constructor(
      titleService: Title
   ) {
      setTimeout(() => titleService.setTitle(`SWA - Courses`))

      // probably a deep link and not cognizant of the course title
      // - go get parent container
      if (this.parentContainerId && !this.selectedJourney()) {
         this._containersService.getContainer(this.parentContainerId).subscribe({
            next: (container) => {
               this.selectedJourney.set(container)
            }
         })
      }
   }
   /*
   * private methods
   * */

   /*
   * public methods
   * */
}
