import { computed, inject, Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Observable, Subject, map, of, switchMap } from 'rxjs'
import { toSignal } from '@angular/core/rxjs-interop'
import { IAchievement } from '@appShared/interfaces/[Model-based]/achievement.interface';
import { IContactAchievementInfo } from '@appShared/interfaces/[Model-based]/contact-achievement-info.interface'
import { ComponentType_ } from '@appShared/services/lookup/[CodeGen]/component-type.domain'
import { environment } from '@appEnvironments/environment'

export interface IClientContactAchievementInfo extends IContactAchievementInfo {
   imageSourceUri: string | null
   isVisible: boolean
}

@Injectable({ providedIn: 'root' })
export class AchievementsService {
   private _achievementsApi = '/api/metrics/achievements';
   private _httpClient = inject(HttpClient)

   /* Contact Achievement Signals */
   private _refreshContactAchievements = new Subject<void>()
   contactAchievements = toSignal(this._refreshContactAchievements.pipe(
      switchMap(() => this._getContactAchievements())
   ), { initialValue: [] as IClientContactAchievementInfo[] })
   contactCourseAchievements = computed<IClientContactAchievementInfo[]>(() =>
      /* course achievements have NO achievementGroupTag */
      this.contactAchievements().filter(ach => ach.isVisible && !!!(ach.achievementGroupTag)))
   contactMentorAchievements = computed<IClientContactAchievementInfo[]>(() =>
      /* mentor achievements HAVE an achievementGroupTag */
      this.contactAchievements().filter(ach => ach.isVisible && !!(ach.achievementGroupTag)))

   /* Area Component Achievement Signals */
   private _refreshAreaComponentAchievements = new Subject<ComponentType_>()
   areaComponentAchievements = toSignal(this._refreshAreaComponentAchievements.pipe(
      switchMap((componentType) => this._getAchievementsForAreaComponent(componentType))
   ), { initialValue: [] as IAchievement[] })

   /*
   * private methods
   * */

   private _getAchievementsForAreaComponent(componentTypeCode?: ComponentType_): Observable<IAchievement[]> {
      if (!componentTypeCode) return of([] as IAchievement[])

      const url = `${this._achievementsApi}/${componentTypeCode}/achievements-for-component`
      const badgeImagesUri = `${environment.publicCdn.uri}/${environment.publicCdn.badgeImagesFolder}`

      return this._httpClient.get<IAchievement[]>(url)
         .pipe(
            map((achievements) =>
               achievements.map((achievement: any) => ({
                  ...achievement,
                  imageSourceUri: achievement.imageSourceUri = achievement.info.hasEarned
                     ? `${badgeImagesUri}/${achievement.info.fromHostedContentToken}.png`
                     : `${badgeImagesUri}/locked.png`
               }))
            )
         )
   }

   private _getContactAchievements(): Observable<IClientContactAchievementInfo[]> {
      const url = `${this._achievementsApi}/achievements-for-contact`
      const badgeImagesUri = `${environment.publicCdn.uri}/${environment.publicCdn.badgeImagesFolder}`

      return this._httpClient.get<IClientContactAchievementInfo[]>(url)
         .pipe(
            map((achievements) => achievements.map(achievement => {
               achievement.isVisible = achievement.isAchievementRequired || !!(achievement.achievementEarned)
               achievement.imageSourceUri = !!(achievement.achievementEarned)
                  ? `${badgeImagesUri}/${achievement.achievementEarnedImageUri}`
                  : `${badgeImagesUri}/${achievement.achievementLockedImageUri}`
               return achievement
            }))
         )
   }

   /*
   * public methods
   * */

   refreshAreaComponentAchievements(componentTypeCode?: ComponentType_): void {
      this._refreshAreaComponentAchievements.next(componentTypeCode)
   }

   refreshContactAchievements(): void {
      this._refreshContactAchievements.next()
   }
}
