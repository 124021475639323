<div class="container">

   <div class="d-flex flex-column mt-3">

      <h2 class="d-flex align-items-center justify-content-center">
         <!--<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="indianred" style="color: indianred!important" class="bi bi-play-circle-fill me-2" viewBox="0 0 16 16">
            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M6.79 5.093A.5.5 0 0 0 6 5.5v5a.5.5 0 0 0 .79.407l3.5-2.5a.5.5 0 0 0 0-.814z" />
         </svg>-->
         <span class="fw-bold text-uppercase text-site-secondary">{{learningJourneys.title}}</span>
      </h2>
      <hr class="mb-4" />
      <!--<p>
         This is all about the course library....Nulla enim arcu, sagittis vitae blandit vitae, dapibus id erat. Donec eros urna, dapibus quis sagittis consequat, feugiat eget leo.
      </p>-->

      <div>

         <app-container-list [parentContainerId]="0"></app-container-list>

      </div>

   </div>

</div>

