import { HomeComponent } from './home/home.component'
import { SiteNotificationComponent } from './site-notification/site-notification.component'

export const components: any[] = [
   HomeComponent,
   SiteNotificationComponent
]

export * from './home/home.component'
export * from './site-notification/site-notification.component'
