<section class="bg-site-tertiary shadow opacity-75 site-footer">
   <div class="container d-flex text-site align-items-center justify-content-center justify-content-md-between px-md-4"
        [hidden]="hideFooter">
      <span class="ms-2 copy fw-bold">Copyright &copy; {{currentYear}} - Southwestern Advantage</span>

      <ul class="nav me-2">
         <li class="nav-item">
            <a href="https://southwestern.com/terms-of-use/"
               class="nav-link text-site fw-bold"
               target="_blank">Terms of Use</a>
         </li>
      </ul>
   </div>
</section>
