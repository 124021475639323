import { ChangeDetectionStrategy, Component } from '@angular/core'

@Component({
   selector: 'app-unauthorized',
   template: `
      <div clas="container">

         <div class="d-flex flex-column p-2 p-lg-5 pt-md-4 mb-3 gap-4 container justify-content-center align-items-center">

            <img src="/assets/images/swa-logo.png" class="logo" />

            <h2 class="text-site-primary text-center">401: Unauthorized</h2>

            <div class="text-center msg">
               We are sorry, you are not authorized to access this site.
               If you feel you should have access, please contact us at
               <a href="mailto:customercare@southwesternadvantage.com" class="contact font-weight-bold">customercare&#64;southwesternadvantage.com</a>
            </div>

         </div>

      </div>
  `,
   styles: [`
      .logo, .msg { max-width:600px;}
      h2 {
        font-size: 40px;
        font-weight: 700;
        color: blue;
      }
      p { max-width: 400px; }
      a.contact, a.contact:hover, a.contact:focus, a.contact:visited {
        color: #029edb;
        text-decoration: none;
      }
  `],
   changeDetection: ChangeDetectionStrategy.OnPush,
   standalone: true,
})

export class UnauthorizedComponent { }
